import React from "react";
import "../assets/common.css";
import axios from "axios";
import { BaseUrl } from "../common/api";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { withRouter, Redirect } from "react-router-dom";
// import CurrencyFormat from "react-currency-format";
import { PrimaryButton, SecondaryButton } from "../assets/static/js/Button";

const cookies = new Cookies();

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {
      // collection: [],
      // collect: [],
      // product: [],
      loading: false,
      url: "",
      colors: [],
      color: [],
      // prices: [],
      // price: [],
      // price_min: [],
      // price_max: [],
      // dates: [],
      // date: [],
      // deliveryDate_min: [],
      // deliveryDate_max: [],
      styles: [],
      style: [],
      // rooms: [],
      // room: [],
      sizes: [],
      size: [],
      error: "",
      testIsChecked: false,
      show: [],
      type_data: [],

      // cart : cookies.get('cart'),
    };
    this._isMounted = false;
    // detecting the language
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
      this.rectangul = "To`rtburchak";
      this.Oval = "Oval";
      this.square = "Kvadrat";
      this.unusual = "Noodatiy";
      this.metric = "Metrik";
    } else {
      this.name = "nameRu";
      this.rectangul = ["Прямоугольник", "pramougolnik"];
      this.Oval = ["Овал", "oval"];
      // this.square = "Квадратный";
      // this.unusual = "Необычный";
      this.metric = ["Дорожка", "dorozhka"];
    }
    // holding the changes by user function binding
    this.handleChange = this.handleChange.bind(this);
    // dropping down selected filters function binding
    this.openContent = this.openContent.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.collect_data = [];
    this.color_data = [];
    // this.price_data = [];
    // this.date_data = [];
    this.style_data = [];
    // this.room_data = [];
    this.check = false;
    this.type_data = [];
    this.sizesId = [];
    this.sizesData = [];
    // this.width_value = [];
    // this.length_value = [];
    this.path = "";
    this.show = [];
    this.types = {
      1: this.rectangul,
      0: this.Oval,
      // KV: this.square,
      // NO: this.unusual,
      2: this.metric,
    };

  }

  async componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.setState({ propers: this.props });

    // deteceting url queries for filter
    this.queryString = require("query-string");
    this.query = new URLSearchParams(this.props.location.search);

  // Detecting URL filters
  const pathWithParams = window.location.pathname;
  
  const filterPath = pathWithParams.split("filter/")[1];
  const filterObject = {};

  if (filterPath) {
    const filters = filterPath.split("/").filter(Boolean);

    filters.forEach((item) => {
      // Split each part by "-or-" first
      const parts = item.split("-or-");

      parts.forEach(part => {
        // Split each segment at the first "-" to separate key from value
        const [key, ...valueParts] = part.split("-");
        const value = valueParts.join("-");
        // Store values in filterObject
        if (filterObject[key]) {
          filterObject[key].push(value);
        } else {
          filterObject[key] = [value];
        }
      });
    });
  }
    
    this.styles = filterObject["style"]
    this.colors = filterObject["color"]
    this.sizes = filterObject["sizes"]
    this.shapes = filterObject["shapes"]

    this.categorySlug = this.props.match.params.slug
    
    
    // this.collection_id = this.query.get("collection_id");
    // this.collection = this.query.get("collection");
    // this.ser = this.query.get("query");
    // this.rooms = this.query.get("rooms");
    // this.length = this.query.get("length");
    // this.width = this.query.get("width");
    // this.price_min = this.query.get("price_min");
    // this.price_max = this.query.get("price_max");
    // this.deliveryDate_min = this.query.get("deliveryDate_min");
    // this.deliveryDate_max = this.query.get("deliveryDate_max");
    // this.prices = this.query.get("prices");
    // this.dates = this.query.get("dates");


    this.nameUz = this.query.get("nameUz");
    this.nameRu = this.query.get("nameRu");


    // adding to list matching queries
    // if (this.collection) {
    //   this.collection
    //     .split(",")
    //     .map((item) => this.collect_data.push(parseInt(item)));
    //   this.show.push(1);
    // }


    if (this.colors) {
      this.colors.map((item) => this.color_data.push(item));
      this.show.push(5);
    }
    if (this.styles) {
      this.styles.map((item) => this.style_data.push(item));
      this.show.push(2);
    }
    if (this.sizes) {
      this.sizes.map((item) => this.sizesData.push(item));
      this.show.push(4);
    }
      

    // if (this.rooms) {
    //   this.rooms.split(",").map((item) => this.room_data.push(parseInt(item)));
    //   this.show.push(3);
    // }

    // if (this.width && this.length) {
    //   this.width.split(",").map((item) => this.width_value.push(item));
    //   this.langth.split(",").map((item) => this.length_value.push(item));
    // }
    // if (this.width_value && this.length_value) {
    //   this.length = this.length_value;
    //   this.width = this.width_value;

    // }
    if (this.shapes) {
      this.type_data = [...this.state.type_data];
      this.shapes.map((item) => this.type_data.push(item));
      this.show.push(6);
      this._isMounted &&
        this.setState({
          type_data: this.type_data,
        });
    }


    // if (this.prices) {
    //   this.prices
    //     .split(",")
    //     .map((item) => this.price_data.push(parseInt(item)));
    //   this.show.push(7);
    // }
    // if (this.dates) {
    //   this.dates.split(",").map((item) => this.date_data.push(parseInt(item)));
    //   this.show.push(8);
    // }
    // sidebar sends two type of filters by category and all
    // if (this.props.location.pathname === "/searcht") {
    //   this.search_url = BaseUrl + `colfilters/`;
    // } else {
    //   this.search_url = BaseUrl + `colfilters/${this.category_id}/`;
    // }
    // await axios
    //   .get(this.search_url)
    //   .then((res) => {
    //     const collection = res.data;
    //     this._isMounted && this.setState({ collection, show: this.show });
    //   })
      // .catch((err) => {
      //   console.log(err);
      //   console.log(err.message);
      //   console.log(err.response);
      // });
    // fetching all data related to sidebar
    await axios.get(BaseUrl + `sidebar/${this.categorySlug}`).then((res) => {
      const data = res.data;
      const colors = data["colors"];
      const styles = data["styles"];
      const sizes = data["sizes"];

      // const prices = data["prices"];
      // const rooms = data["rooms"];
      // const dates = data["dates"];
      this._isMounted &&
        this.setState({
          colors,
          styles,
          sizes,

          // prices,
          // rooms,
          // dates,

        });
    });
  }


  // holds user's entering width and length for filter
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "width") {
      this.width = value;
    }
    if (name === "length") {
      this.length = value;
    }
  };
  // get user inpputs as a filters


  getData = async (row, e, data) => {
    if (e.target.checked) {
      await data.push(row);
    } else {
      await data.splice(data.indexOf(row), 1);
    }
    if (data === this.type_data) {
      this._isMounted &&
        await this.setState({
          type_data: this.type_data,
        });
    }
    this.handleSubmit(e)
  };

  // collecting selected filters and sends the request to api
  handleSubmit = (e) => {
    this.newQuryPath = this.queryString.stringify(
      {
        limit: 21,
        offset: 0,
        region: cookies.get("Region"),
      },
      {
        skipNull: true,
        arrayFormat: "comma",
      }
    );

    const filterParams = {
      style: this.style_data,
      color: this.color_data,
      sizes: this.sizesData,
      shapes: this.type_data,
    };
    // Перебираем объект и формируем части URL для каждого фильтра
    const urlParts = Object.entries(filterParams).reduce((acc, [key, values]) => {
      if (values && values.length > 0) {
        // Формируем строки в формате key-value и соединяем значения через "-or-"
        const paramString = values.map(value => `${key}-${value}`).join("-or-");
        acc.push(paramString);
      }
      return acc;
    }, []);
  
    // Объединяем части URL через "/" и добавляем новую часть URL
    this.generatePath = urlParts.join("/");

    this.pathname = this.props.location.pathname;
    
    
    this.pathname = this.pathname.split("/").slice(0, 4).join("/") + "/" + this.generatePath




    this._isMounted &&
      this.setState({
        loading: true,
      });




    // posting matching query for collecting filters as a statistics
    // axios
    //   .post(BaseUrl + "filters/", { filters: this.new_pathname })
    //   .catch((error) => {
    //     console.log(error);
    //     console.log(error.message);
    //     console.log(error.response);
    //   });
  };


  // clear all filter by refreshing the page

  clearFilter = (e) => {
    e.preventDefault();
    this.queryString = require("query-string");
    const parsed = this.queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    if (parsed.offset) {
      this.offset = parsed.offset;
    } else {
      this.offset = 0;
    }


    // if (parsed.collection) {
    //   let collection = parsed.collection;
    //   if (typeof collection === "string") {
    //     this.co_id = collection;
    //   } else {
    //     this.co_id = collection[0];
    //   }
    // }

    const fullPath = this.props.location.pathname;
    // Обрезаем строку, чтобы получить только часть до "filter"
    const basePath = fullPath.split("filter/")[0] + "filter/";
    this.refPath = basePath + `?offset=${this.offset}`;

    // if (!this.collection) {
    //   this.refPath =
    //     basePath +
    //     // `?category_id=${this.category_id}&offset=${this.offset}`;
    //     `?offset=${this.offset}`;
    // } else {
    //   this.refPath =
    //     this.props.location.pathname +
    //     `?category_id=${this.category_id}&collection=${this.co_id}&offset=${this.offset}`;
    // }
    
    this.props.history.push(this.refPath);
    window.location.reload(false);
  };
  // dropping down selected filters
  openContent = (id) => {
    this.show = this.state.show;
    if (this.show.includes(id)) {
      this.show.splice(this.show.indexOf(id), 1);
    } else {
      this.show.push(id);
    }
    this._isMounted &&
      this.setState({
        show: this.show,
      });
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    return (
      <div
        className={` ${
          this.props.filterShow
            ? "filter-close"
            : "filters-container filters-section"
        }`}
      >
        <div className="filters-box">
          <div className={` mr-2 pt-2 `}>
            <div className="scrol-filters">
              {/* in the category.js filter doesn't show filters by collections */}
              {/* {(this.props.location.pathname === "/collection" ||
                this.props.location.pathname === "/searcht") && (
                <div
                  className={this.state.show.includes(1) ? "show-content" : ""}
                >
                  <div
                    className="filter-title pt-2"
                    onClick={() => this.openContent(1)}
                  >
                    <span className="text medium">{t("Collections.text")}</span>
                    <i className="fa-solid fa-chevron-down"></i>
                  </div>
                  <div className="content">
                    <div className="filter-fields">
                      {this.state.collection.map((col) => (
                        <label
                          key={col.id}
                          htmlFor={col.id + col[this.name]}
                          className="checkbox-flex checkbox-container"
                        >
                          <input
                            type="checkbox"
                            id={col.id + col[this.name]}
                            name={col.id}
                            value="collection"
                            defaultChecked={
                              this.collect_data.includes(col.id)
                                ? true
                                : this.check
                            }
                            onChange={(e) =>
                              this.getData(col.id, e, this.collect_data)
                            }
                          />
                          <span className="checkmark"></span>
                          <span className="checktitle text-sm">
                            {col.nameUz}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              )} */}

              <div
                className={this.state.show.includes(2) ? "show-content" : ""}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(2)}
                >
                  <span className="text medium">{t("Styles.text")}</span>
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {this.state.styles.map((col) => (
                      <label
                        key={col.id}
                        htmlFor={col.id + col[this.name]}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={col.id + col[this.name]}
                          name={col.id}
                          value="styles"
                          defaultChecked={
                            this.style_data.includes(col.slug) ? true : this.check
                          }
                          onChange={(e) =>
                            this.getData(col.slug, e, this.style_data)
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          {col[this.name]}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              {/* <div
                className={this.state.show.includes(3) ? "show-content" : ""}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(3)}
                >
                  <span className="text medium">{t("Rooms.text")}</span>
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {this.state.rooms.map((col) => (
                      <label
                        key={col.id}
                        htmlFor={col.id + col[this.name]}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={col.id + col[this.name]}
                          name={col.id}
                          value="rooms"
                          defaultChecked={
                            this.room_data.includes(col.id) ? true : this.check
                          }
                          onChange={(e) =>
                            this.getData(col.id, e, this.room_data)
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          {col[this.name]}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div> */}

              <div
                className={this.state.show.includes(4) ? "show-content" : ""}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(4)}
                >
                  <span className="text medium">{t("Sizes.text")}</span>
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
                <div className="content">
                  <div className="filter-fields">
                  {this.state.sizes.map((col) => (
                      <label
                        key={col.id}
                        htmlFor={col.vSizeId}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={col.vSizeId}
                          name="sizes"
                          value="sizes"
                          defaultChecked={
                            this.sizesData.includes(col.slug) ? true : this.check
                          }
                          onChange={(e) =>
                            this.getData(col.slug, e, this.sizesData)
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          {col.width} x {col.length}
                        </span>
                      </label>
                    ))}
                    {/* <div style={{ position: "relative" }}>
                      <label>{t("Width.text")}:</label>
                      <br />
                      <input
                        type="text"
                        defaultValue={this.width_value}
                        name="width"
                        onChange={this.handleChange}
                        className="input-container"
                      />
                      <span className="unit">{t("cm.text")}</span>
                    </div>
                    <div style={{ position: "relative" }}>
                      <label>{t("Height.text")}:</label>
                      <br />
                      <input
                        type="text"
                        defaultValue={this.length_value}
                        name="length"
                        onChange={this.handleChange}
                        className="input-container"
                      />
                      <span className="unit">{t("cm.text")}</span>
                    </div> */}
                  </div>
                </div>
              </div>

              <div
                className={this.state.show.includes(5) ? "show-content" : ""}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(5)}
                >
                  <span className="text medium">{t("Color.text")}</span>
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {this.state.colors.map((col) => (
                      <label
                        key={col.id}
                        htmlFor={col.id + col[this.name]}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={col.id + col[this.name]}
                          name={col.id}
                          value="colors"
                          defaultChecked={
                            this.color_data.includes(col.slug) ? true : this.check
                          }
                          onChange={(e) =>
                            this.getData(col.slug, e, this.color_data)
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          {col[this.name]}
                        </span>
                        <div className="colors">
                          <div
                            className="single-color"
                            style={{ background: col.color_code }}
                          ></div>
                          <div
                            className="single-color"
                            style={{ background: col.extra_color }}
                          ></div>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              <div
                className={this.state.show.includes(6) ? "show-content" : ""}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(6)}
                >
                  <span className="text medium">{t("Shape.text")}</span>
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {Object.keys(this.types).map((key, index) => (
                      <label
                        key={index}
                        htmlFor={index + key}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={index + key}
                          name={key}
                          value="shapes"
                          checked={
                            this.state.type_data.includes(this.types[key][1])
                              ? true
                              : this.check
                          }
                          onChange={(e) => this.getData(this.types[key][1], e, this.type_data)}
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          {this.types[key][0]}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              {/* <div
                className={this.state.show.includes(7) ? "show-content" : ""}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(7)}
                >
                  <span className="text medium">{t("Price.text")}</span>
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {this.state.prices.map((col) => (
                      <label
                        key={col.id}
                        htmlFor={col.price_max}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={col.price_max}
                          defaultValue={col.id}
                          name="prices"
                          defaultChecked={
                            this.price_data.includes(col.id) ? true : this.check
                          }
                          onChange={(e) =>
                            this.getData(col.id, e, this.price_data)
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          <span>
                            <CurrencyFormat
                              value={col.price_min}
                              displayType={"text"}
                              thousandSeparator=" "
                            />
                          </span>

                          <span> - </span>
                          <span>
                            <CurrencyFormat
                              value={col.price_max}
                              displayType={"text"}
                              thousandSeparator=" "
                            />
                          </span>
                          <span> {t("suffix.text")}</span>
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div> */}

              {/* <div
                className={this.state.show.includes(8) ? "show-content" : ""}
              >
                <div
                  className="filter-title pt-2"
                  onClick={() => this.openContent(8)}
                >
                  <span className="text medium">{t("DeliveryDate.text")}</span>
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
                <div className="content">
                  <div className="filter-fields">
                    {this.state.dates.map((col) => (
                      <label
                        key={col.id}
                        htmlFor={col.date_max}
                        className="checkbox-flex checkbox-container"
                      >
                        <input
                          type="checkbox"
                          id={col.date_max}
                          name="dates"
                          defaultValue={col.id}
                          defaultChecked={
                            this.date_data.includes(col.id) ? true : this.check
                          }
                          onChange={(e) =>
                            this.getData(col.id, e, this.date_data)
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="checktitle text-sm">
                          <span>{col.date_min}</span>

                          <span> - </span>
                          <span>{col.date_max}</span>
                          <span> {t("day.text")}</span>
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div> */}
            </div>
          {this.props.button && (
            <PrimaryButton
            onClick={(e) => {
              this.handleSubmit(e);
              // this.props.filterToggle();
              this.props.CloseFilter();
            }}
            value={t("UseFilters.text")}
          />
          )}
            
            <SecondaryButton
              onClick={(e) => {
                this.clearFilter(e);
                // this.props.filterToggle();
                this.props.CloseFilter();

              }}
              value={t("ClearFilters.text")}
            />
            {this.state.loading && (
              <Redirect
                to={{
                  pathname: this.pathname,
                  search: `?${this.newQuryPath}`,
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withTranslation()(SideBar));

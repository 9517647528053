// import axios from "axios";
// import React from "react";
// import { withRouter } from "react-router-dom";
// import { BaseUrl } from "../common/api";
// import Cookies from "universal-cookie";
// import ReactHtmlParser from "html-react-parser";

// const cookies = new Cookies();
// // static pages renders with static id's
// //id=1 => About
// // id=2 => CodeOfUse
// // id=4 FAQ
// class staticPage extends React.Component {
//   constructor(props) {
//     super(props);
//     // Initialize state and class variables
//     this.state = {
//       pages: [],
//     };
//     this._isMounted = false;
//     // detecting the language
//     if (cookies.get("Language")) {
//       this.lang = cookies.get("Language");
//     } else {
//       this.lang = "uz";
//     }
//     if (this.lang === "uz") {
//       this.title = "titleUz";
//       this.text = "textUz";
//     } else {
//       this.title = "titleRu";
//       this.text = "textRu";
//     }
//   }

//   componentDidMount() {
//     window.scrollTo({ top: 0, left: 0, behavior: "auto" });
//     this._isMounted = true;
//     const id = this.props.match.params.id;
//     // fetching data from api
//     axios.get(BaseUrl + "pages/" + id + "/").then((res) => {
//       const pages = res.data;
//       this._isMounted &&
//         this.setState({
//           pages,
//         });
//     });
//   }
//   // fetching rerendered data without refreshing the page
//   componentDidUpdate(prevProps) {
//     if (this.props.match.params.id !== prevProps.match.params.id) {
//       window.scrollTo({ top: 0, left: 0, behavior: "auto" });
//       const id = this.props.match.params.id;
//       axios.get(BaseUrl + "pages/" + id + "/").then((res) => {
//         const pages = res.data;
//         this._isMounted &&
//           this.setState({
//             pages,
//           });
//       });
//     }
//   }
//   componentWillUnmount() {
//     this._isMounted = false;
//   }
//   render() {
//     return (
//       <div className="main">
//         <div className="container py-2">
//           <div>
//             <h1 className="text-title">{this.state.pages[this.title]}</h1>
//           </div>
//           <div className="py-2">
//             {/* rendering data generated by tiny */}
//             {this.state.pages.textRu &&
//               ReactHtmlParser(this.state.pages[this.text])}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
// export default withRouter(staticPage);


import axios from "axios";
import React from "react";
import { withRouter } from "react-router-dom";
import { BaseUrl } from "../common/api";
import Cookies from "universal-cookie";
import ReactHtmlParser from "html-react-parser";

const cookies = new Cookies();

// Static pages render with static IDs
// id=1 => About
// id=2 => CodeOfUse
// id=4 => FAQ

class StaticPage extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {
      pages: [],
    };
    this._isMounted = false;

    // Detecting the language
    this.lang = cookies.get("Language") || "uz";
    this.title = this.lang === "uz" ? "titleUz" : "titleRu";
    this.text = this.lang === "uz" ? "textUz" : "textRu";
  }

  // Helper function to determine page ID based on pathname
  getPageIdFromPath = () => {
    const path = window.location.pathname;
    if (path === "/about") {
      return 1;
    } else if (path === "/faq") {
      return 4;
    } else if (path === "/privacy") {
      return 2;
    }
    return this.props.match.params.id; // Fallback to `id` from `props` if available
  };

  // Fetch data based on dynamic ID
  fetchPageData = (id) => {
    axios.get(`${BaseUrl}pages/${id}/`).then((res) => {
      const pages = res.data;
      if (this._isMounted) {
        this.setState({ pages });
      }
    });
  };

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    this._isMounted = true;
    const id = this.getPageIdFromPath();
    this.fetchPageData(id);
  }

  componentDidUpdate(prevProps) {
    if (window.location.pathname !== prevProps.location.pathname) {
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
      const id = this.getPageIdFromPath();
      this.fetchPageData(id);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="main">
        <div className="container py-2">
          <div>
            <h1 className="text-title">{this.state.pages[this.title]}</h1>
          </div>
          <div className="py-2">
            {/* Render data generated by TinyMCE */}
            {this.state.pages[this.text] &&
              ReactHtmlParser(this.state.pages[this.text])}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(StaticPage);
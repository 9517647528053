import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BaseUrl } from "./api";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

import "swiper/css/bundle";

const cookies = new Cookies();

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Post extends React.Component {
  constructor() {
    super();
    this.state = {
      post: [],
    };
    this._isMounted = false;

    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
      this.title = "titleUz";
      this.text = "textUz";
    } else {
      this.name = "nameRu";
      this.title = "titleRu";
      this.text = "textRu";
    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    this._isMounted = true;
    let { slug } = this.props.params;
    axios.get(BaseUrl + `posts/${slug}/`).then((res) => {

    let post = res.data;

      this.data = post.createdAt.slice(0, 10);
      this._isMounted && this.setState({ post }, this.injectSchema);
      document.title = this.state.post[this.title]
        ? this.state.post[this.title]
        : "SAG";
    });
  }
  extractDescription = (htmlContent) =>{
    // Step 1: Strip HTML tags using a DOM parser
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const plainText = tempDiv.textContent || tempDiv.innerText || '';
  
    // Step 2: Split the text by period to get sentences
    const sentences = plainText.split('.').map(sentence => sentence.trim()).filter(Boolean); // Filter out empty entries
  
    // Step 3: Extract the first 1-2 sentences, or fewer if less available
    const description = sentences.slice(0, 2).join('. ');
  
    // Add a period if there are any sentences
    return description ? description + '.' : '';
  }

  injectSchema = () => {
    const {post} = this.state;
    if (!post) return;

    const schema = {
      "@context": "https://schema.org",	
      "@type": "NewsArticle",	
      "mainEntityOfPage": {	
        "@type": "WebPage",	
        "@id": `https://sagkovry.ru/post/${post.id}`},	// Ссылка на страницу статьи
      "headline": `${post.seoTitleRu}`,	// Заголовок статьи h1
      "image": [	
        post.image,
      ],	// Заглавное фото статьи и фото в тексте статьи
      "datePublished": post.createdAt,	// Дата публикации статьи
      "dateModified": post.updatedAt,	// Дата изменения статьи (в этом случае совпадает с датой публикации статьи)
      "author": {	
        "@type": "Person",	
        "name": "SAG"},	
      "publisher": {	
        "@type": "Organization",	
        "name": "SAG",	
        "logo": {	
          "@type": "ImageObject",	
          "url": "https://sagkovry.ru/media/images/%D0%9B%D0%BE%D0%B3%D0%BE_SAG_%D0%BA%D0%BE%D0%B2%D1%80%D1%8B_1_1_Traced-2_1.png"}},	// Указать логотип сайта
      "description": this.extractDescription(post.textRu)
    }	// Первый абзац статьи, но менее 250 символов. Т.е. необходимо взять 1-2 предложения до точки, восклицательного или, вопросительного знака.
     // Create and inject the script tag into the head
     const script = document.createElement('script');
     script.type = 'application/ld+json';
     script.innerHTML = JSON.stringify(schema);
     document.head.appendChild(script);
   
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const post = this.state.post;

    return (
      <div className="main py-3">
        <div className="container">
          <div className="blog-image">
            <img src={post.image} alt={post.titleRu} />
          </div>
          <div className="blog-date">
            <i className="fa-regular fa-calendar"></i>
            <p>{this.data}</p>
          </div>
          <h1 className="text-title">{post[this.title]}</h1>
          {post.textUz && ReactHtmlParser(post[this.text])}
        </div>
      </div>
    );
  }
}

export default withParams(withTranslation()(Post));

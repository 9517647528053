import axios from "axios";
import React from "react";
import Cookies from "universal-cookie";

import { withRouter } from "react-router";
import { BaseUrl } from "../common/api";
import Mapleaflet from "../common/mapLeaflet";
import { withTranslation } from "react-i18next";

const cookies = new Cookies();
// page where shows all shops' informations with leaflet
class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      regions: [],
      positions: [],
      reg: "",
      center: [],
      show: [],
    };
    this._isMounted = false;
    this.positions = [];
    this.pos = {};
    this.center = [];
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "name_uz";
      this.regname = "nameUz";
      this.address = "address_uz";
    } else {
      this.name = "name_ru";
      this.regname = "nameRu";
      this.address = "address_ru";
    }
  }
  // fetching all data in regions/ api
  async componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    const schema = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "SAG ковры",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "улица Толмачёва, 22",
          "addressRegion": "Свердловская область",
          "addressLocality": "Екатеринбург",
          "addressCountry": "RU",
          "postalCode": "620000",
      },
      "image": "указать ссылку на логотип компании",
      "email": "info.ru@sag.uz",
      "telephone": "+79220303495",
      "openingHours":["Mo 09:00-20:00","Tu 09:00-20:00","We 09:00-20:00","Th 09:00-20:00","Fr 09:00-20:00","Sa 09:00-20:00","Su 09:00-20:00"],
      "openingHoursSpecification": [
          {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Monday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Tuesday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Wednesday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Thursday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Friday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Saturday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Sunday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
      ]
  
    }

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(schema);
    document.head.appendChild(script);
    
    this._isMounted = true;
    await axios
      .get(BaseUrl + "regions/")
      .then((res) => {
        let regions = res.data;
        regions = regions.filter((el) => el.isActive === true);
        this._isMounted &&
          this.setState({
            regions: regions,
          });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
        console.log(err.response);
      });
    this.state.regions.map((region) =>
      region.shops.map((shop) => {
        this.pos["lng"] = shop.lng;
        this.pos["lat"] = shop.lat;
        this.positions.push(this.pos);
        this.pos = {};
        return this.positions;
      })
    );
    const reg = cookies.get("Region");

    this.reg_id = this.state.regions.find((item) => item.id === parseInt(reg));

    // gets first shops location in order to give center for leaflet

    if (this.reg_id.shops.length > 0) {
      this.center.push(this.reg_id.shops[0].lng);
      this.center.push(this.reg_id.shops[0].lat);
    }
    this._isMounted &&
      this.setState({
        positions: this.positions,
        center: this.center,
      });
  }
  // formating phone
  phoneNumberFormat = (num) => {
    return num.replace(
      /(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/,
      " $1 ($2)-$3-$4-$5"
    );
  };
  // shows modal which holds an informations releted to shops
  showModal = (id) => {
    this.show = this.state.show;
    if (this.show.includes(id)) {
      this.show.splice(this.show.indexOf(id), 1);
    } else {
      this.show.push(id);
    }
    this._isMounted &&
      this.setState({
        show: this.show,
      });
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    return (
      <div className="main">
        <div className="container">
          <h1 className="text-title"> {t("ourAddress.text")}</h1>
          <div className="addresses">
            {/* iterating regions' information */}
            {this.state.regions
              .filter((el) => el.shops.length > 0)
              .map((region) => (
                <div
                  key={region.id}
                  className="collapsible"
                  onClick={() => this.showModal(region.id)}
                >
                  <div className="contact-card">
                    <div className="contact-inform">
                      <div className="contact-image">
                        <img src={region.image} alt={region.nameUz} />
                      </div>
                      <div className="ml-2">
                        <strong className="tex-bold text-lg">
                          {region[this.regname]}
                        </strong>
                        <p className="text">
                          {t("allShops.text")}: {region.shops.length}
                        </p>
                      </div>
                    </div>
                    <i className="fa-solid fa-chevron-down fa-xs mx-1"></i>
                  </div>
                  {/* must be closed */}
                  {/* {showing the modal } */}
                  {this.state.show.includes(region.id) && (
                    <div className="contact-content mt-3 p-2">
                      {region.shops.map((shop) => (
                        <div key={shop.id}>
                          <div className="seperator"></div>
                          <div className="shop-container">
                            <h2>
                              <strong className="text text-bold">
                                {shop[this.name]}
                              </strong>
                            </h2>
                            <h5>
                              <strong className="text-sm text-bold">
                                📍{shop[this.address]}
                              </strong>
                            </h5>
                            <br />
                            {shop.extra_phone && (
                              <div className="contact-phone py-1">
                                <i className="fa-solid fa-phone-volume"></i>
                                <span className="ml-1">
                                  {" "}
                                  {this.phoneNumberFormat(shop.extra_phone)}
                                </span>
                              </div>
                            )}
                            {shop.main_phone && (
                              <div className="contact-phone">
                                <i className="fa-solid fa-phone-volume"></i>
                                <span className="ml-1">
                                  {" "}
                                  {this.phoneNumberFormat(shop.main_phone)}
                                </span>
                              </div>
                            )}
                            <div className="py-1">
                              <img src={shop.image} alt={shop.name_uz} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div>
            <h1 className="text-title"> {t("mapAddress.text")}</h1>
           
            <Mapleaflet
              positions={this.state.positions}
              center={this.state.center}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Contacts));

/* global cp */
import axios from "axios";
import { BaseUrl } from "../common/api";



export const pay = function (datas) {    

    const data = datas
   

  
    var widget = new cp.CloudPayments();
       widget.pay('charge', // или 'charge'
           { //options
               publicId: 'pk_976ccf5182f029caa4bda62f9f36f', // id из личного кабинета
               description: 'Оплата товаров в sagkovry.ru', //назначение
               amount: data.price, //сумма
               currency: 'RUB', //валюта
              //  accountId: 'user@example.com', //идентификатор плательщика (необязательно)
               invoiceId: data.id, //номер заказа  (необязательно)
              //  email: 'user@example.com', //email плательщика (необязательно)
               skin: "mini", //дизайн виджета (необязательно)
               data: {
                   myProp: 'myProp value' 
               },  configuration: {
                common: {
                    successRedirectUrl: `http://localhost:3000/orderInfo/`, // адреса для перенаправления 
                    failRedirectUrl: "https://{ваш сайт}/fail"        // при оплате по T-Pay
                }
            },
           },
           {
               onSuccess: function (options) { // success
                   //действие при успешной оплате
                   console.log("success"); 
                //    window.location.href = `http://localhost:3000/orderInfo/${data.url}`;

               },
               onFail: function (reason, options) { // fail
                   //действие при неуспешной оплате
                   console.log("fail");
                
               },
               onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                   //например вызов вашей аналитики Facebook Pixel
                   console.log("complete");
                   const data = datas
                   const payData = {
                       "InvoiceId": data.id,
                   }
                    axios.post(BaseUrl + "find-payments", payData 
                    ).then((response) => {
                           
                        // window.location.href = `https://sagkovry.ru/orderInfo/${data.url}`;
                        console.log(response.data)

                        }).catch((err) => {
                            console.log(err)
                        })


               }
           }
       )
       
   };